<template>
  <div>
    <v-card
      v-if="(planning.length = !0)"
      class="ml-5 mt-10 mb-10 pa-0"
      max-width="481"
      color="#E3E3E3"
    >
      <!-- <v-app-bar height="30" flat dense dark> -->
      <v-row no-gutters justify="center" align="center" class="rel">
        <v-col cols="12"
          >{{ $moment(planning.heure_debut, "HH:mm:ss").format("HH[h]mm") }}
          -
          {{ $moment(planning.heure_fin, "HH:mm:ss").format("HH[h]mm") }}</v-col
        >

        <div v-if="planning.compteur > 0" class="vignette-green">
          {{ planning.compteur }}/{{ planning.max_collecte }}
        </div>
        <div v-else class="vignette-red">
          {{ planning.compteur }}/{{ planning.max_collecte }}
        </div>
      </v-row>

      <v-row class="text-left px-5 mt-0 ">
        <v-col cols="12">
          <v-row class="my-1" no-gutters>
            <v-col cols="3" md="2" sm="4">
              <span
                style="font-size: 0.71rem; margin-top: 2px"
                class="font-weight-bold"
                >MARCHÉ</span
              ></v-col
            >
            <v-col cols="3" md="3" sm="6">
              <span class="ml-2" style="font-size: 1rem"
                ><strong>{{ planning.Secteur.Marche.marche }}</strong></span
              ></v-col
            >

            <v-col cols="2" md="2" sm="4">
              <span
                style="font-size: 0.71rem; margin-top: 2px"
                class="font-weight-bold"
                >SECTEUR</span
              ></v-col
            >

            <v-col cols="3" md="3" sm="4">
              <span
                class="ml-2"
                style="font-size: 1rem; text-transform: capitalize"
                ><strong>{{ planning.Secteur.nom_secteur }}</strong></span
              >
              <img
                class="ml-1 logo_particulier"
                src="@/assets/images/dashboard/particulier.png"
                alt="Particulier"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="line_colcard px5"></div>
      <v-row class="text-left">
        <v-col cols="12">
          <v-row align="left" class="my-1 px-5" no-gutters>
            <v-col cols="2" sm="4" md="2">
              <span
                style="font-size: 0.71rem; margin-top: 2px"
                class="font-weight-bold"
                >CUBAGE TOTAL</span
              >
            </v-col>

            <v-col cols="3" sm="6" md="3">
              <span class="ml-2" style="font-size: 1rem"
                ><strong>{{ planning.volume_total }} m³</strong></span
              >
              <!-- <span
                v-if="planning.volume_total > planning.Camion.volume"
                style="color: #efbf00"
                ><i class="fas fa-exclamation-triangle"></i
              ></span> -->
            </v-col>

            <v-col cols="3" sm="4" md="2">
              <span
                style="font-size: 0.71rem; margin-top: 2px"
                class="font-weight-bold"
                >POIDS TOTAL</span
              >
            </v-col>

            <v-col cols="3" sm="6" md="2">
              <span
                class="ml-2"
                style="font-size: 1rem; text-transform: capitalize"
                ><strong
                  >{{ (planning.poids_total / 1000).toFixed(2) }} t</strong
                ></span
              >
            </v-col>

            <v-col cols="3" sm="12" md="3">
              <a class="rose" @click="elementToPopup(planning)"
                >voir le détail</a
              >
            </v-col>

            <!-- <v-col cols="3">

            </v-col> -->

            <!-- <v-col
              v-if="planning.volume_total > planning.Camion.volume"
              cols="1"
            >
              <span style="color: #efbf00"
                ><i class="fas fa-exclamation-triangle"></i
              ></span>
            </v-col> -->

            <!-- <v-col cols="3" sm="12" md="3">

            </v-col>


            <v-col cols="2" sm="12" md="2"> </v-col> -->
          </v-row>
        </v-col>
      </v-row>

      <div class="line_colcard"></div>
      <v-row class="text-left px-5">
        <v-col cols="12">
          <v-row class="my-1" no-gutters align="center">
            <v-col cols="2" md="2" sm="4">
              <span class="font-weight-bold" style="font-size: 0.71rem"
                >CAMION</span
              >
            </v-col>
            <v-col cols="7" md="7" sm="5" class="pr-1 ml-2">
              <ValidationProvider
                v-if="field.type == 'select'"
                :rules="field.rules"
                :name="field.label"
                ><v-select
                  style="font-size: 12px; text-transform: lowercase"
                  v-model="planning.id_camion"
                  :items="items"
                  item-text="label"
                  item-value="id_camion"
                  background-color="#fff"
                  hide-details
                  height="20px"
                  solo
                ></v-select
              ></ValidationProvider>
            </v-col>
            <v-col cols="3" md="2" sm="2">
              <v-btn
                dark
                class="text-lowercase font-weight-bold btn_valider ml-1 "
                dense
                block
                rounded
                color="#D61111"
                large
                height="20"
                @click="
                  $emit('updateCamion', {
                    camion: planning.id_camion,
                    creneau: planning.id_creneau,
                  });
                  snackbar = true;
                "
                >valider</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="line_colcard"></div>

      <v-row class="my-1 px-5 text-left clients_height">
        <v-col cols="2" md="3" sm="4">
          <span
            class="font-weight-bold text-left"
            style="font-size: 0.71rem; margin-top: 2px"
            >CLIENTS({{ clients.length }})</span
          >
        </v-col>
        <v-col
          v-if="clients.length > 0"
          class="pt-2 text-left overflow-y-auto"
          style="max-height: 230px;"
          cols="10"
          md="9"
          sm="8"
        >
          <v-list class="client-list">
            <v-list-item class="ml-1 mt-1 client-list-item"  v-for="(client,i) in clients" :key="i">
              <span v-if="client.id_type_client == 1" class="text-capitalize font-weight-bold">
                {{ client.civilite == "monsieur" ? "mr" : "mme" }} {{ client.prenom }} {{ client.nom }}
              </span>
              <span v-else class="text-capitalize font-weight-bold">
                {{ client.bailleur }}
              </span>
              <span v-if="client.countCollecte >1"> x {{client.countCollecte}} collectes</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-if="client.id_type_client == 1"
                    v-bind="attrs"
                    v-on="on"
                    align="center"
                    class="logo_particulier ml-2 mb-3 client-list-img "
                    src="@/assets/images/dashboard/particulier.png"
                    alt="Particulier"
                  />
                  <img
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    align="center"
                    class="logo_bailleur ml-2 mb-3"
                    src="@/assets/images/clients/logo-bailleur.png"
                    alt="Bailleur"
                  />
                </template>
                <span>{{ client.adresse }}, {{client.code_postal}} {{client.ville}}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>

        </v-col>

        <v-col class="pt-1" cols="6" v-else>
          <div class="my-2">Aucun client</div>
        </v-col>
      </v-row>

      <div class="line_colcard mt-10"></div>

      <v-row class="text-left px-5">
        <v-col
          class="text-center"
          v-for="btn in btnExport"
          :key="btn.name"
          cols="12"
        >
          <v-btn
            class="text-lowercase export"
            small
            rounded
            dark
            color="#AF2C70"
            @click="exportTournee()"
            ><img
              class="mr-3"
              src="@/assets/images/dashboard/export.png"
              alt=""
            />{{ btn.name }}</v-btn
          >

          <v-btn
            v-if="
              planning.Secteur.Marche.marche == 'SIREDOM' && clients.length > 0
            "
            class="ml-1 text-lowercase export"
            small
            rounded
            dark
            color="#AF2C70"
            @click="bon_enlevement()"
            ><img
              class="mr-3"
              src="@/assets/images/dashboard/export.png"
              alt=""
            />bons d'enlèvements</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <v-col
      cols="12"
      color="#333"
      align="center"
      class="font-weight-bold text-center text-uppercase text-h2"
      v-else
    >
      Aucune collecte n'est prévue ce jour
    </v-col>

    <div id="snack">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ message }}
      </v-snackbar>
    </div>

    <v-row>
      <PopupPlanningDetail
        :title="title"
        :planningDetails="planningDetails"
        :show="show"
        @close="(show = false), (planningDetails = [])"
      />
    </v-row>
  </div>
</template>

<script>
import api from "@/api/marches";
import PopupPlanningDetail from "./popupPlanningDetail.vue";
export default {
  name: "collectCard",

  data() {
    return {
      clients: [],
      hover: false,
      green: "vignette-green",
      red: "vignette-red",
      color: "#93b121",
      id_camion: this.planning.Secteur.id_camion,
      snackbar: false,
      timeout: 3000,
      message: "Le camion a été mis à jour.",
      show: false,
      title: "sds",
      planningDetails: [],
    };
  },

  components: { PopupPlanningDetail },

  props: {
    planning: {
      type: Object,
      default: () => {},
    },
    camions: {
      type: Array,
      default: () => [],
    },
    btnExport: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    PopupPlanningDetail() {
      this.show = true;
    },
    elementToPopup(planning) {
      console.log(planning);
      planning.Collectes.forEach((collecte) => {
        var client =
          collecte.Client.prenom + " " + collecte.Client.nom.toUpperCase();
        var nbitem = collecte.Collectibles_a_Recuperers.length;
        var cubage = collecte.volume;
        var poids = collecte.poids;

        var planningDetailsObj = {
          client: client,
          nbitem: nbitem,
          cubage: cubage,
          poids: poids,
        };

        this.planningDetails.push(planningDetailsObj);
      });
      this.title =
        this.$moment(this.planning.date).format("dddd LL") +
        " - " +
        this.$moment(planning.heure_debut, "HH:mm:ss").format("HH[h]mm") +
        " À " +
        this.$moment(planning.heure_fin, "HH:mm:ss").format("HH[h]mm");
      this.show = true;
    },
    displayClients() {
      this.clients = this.planning.clients;
    },
    exportTournee() {
      var data = {
        id_marche: this.planning.Secteur.id_marche,
        id_secteur: [this.planning.Secteur.id_secteur],
        type: ["collecte réalisée"],
        date_debut: this.planning.date,
        date_fin: this.planning.date,
        heure_debut: this.planning.heure_debut,
        heure_fin: this.planning.heure_fin,
      };

      api
        .exportTournee(data)
        .then((res) => {
          console.log(res);
          if (res.data.type == "text/plain") {
            this.color = "red darken-2";
            this.message = "pas de données disponibles";
            this.snackbar = true;
          } else {
            var filename = `tournee_du_${this.$moment(data.date_debut).format(
              "DD-MM-YYYY"
            )}_${this.planning.Secteur.Marche.marche}_${
              this.planning.Secteur.nom_secteur
            }.xlsx`;
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
      console.log("params export tournee", data);
    },

    async bon_enlevement() {
      var data = {
        id_marche: this.planning.Secteur.id_marche,
        id_secteur: [this.planning.Secteur.id_secteur],
        type: ["collecte réalisée"],
        date_debut: this.planning.date,
        date_fin: this.planning.date,
        heure_debut: this.planning.heure_debut,
        heure_fin: this.planning.heure_fin,
      };

      this.color = "#93b122";
      this.errors = ["Veuillez patientez jusqu'au téléchargement"];
      this.message = "Veuillez patientez jusqu'au téléchargement";
      this.snackbar = true;
      this.timeout = 999999;
      api
        .bonEnlevementPdf(data)
        .then((res) => {
          console.log(res, "bon enlevement");
          if (res.data.type == "text/plain") {
            this.color = "red darken-2";
            this.message = "pas de données disponibles";
            this.snackbar = true;
          } else {
            this.snackbar = false;
            var filePath = res.data.message;
            console.log(filePath);
            var filename = `bon_enlevement_${this.$moment(
              data.date_debut
            ).format("DD-MM-YYYY")}_${this.planning.Secteur.Marche.marche}_${
              this.planning.Secteur.nom_secteur
            }.pdf`;


                    let form = {
                filePath: filePath,
                filename: filename,
              };

              setTimeout(function () { this.download(form) }.bind(this), 5000)
              // var data = { filePath: filePath, filename: filename };

            // const url = window.URL.createObjectURL(
            //   new Blob([res.data], { type: "text/plain" })
            // );
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("target", "_blank");
            // link.setAttribute("download", filename);
            // document.body.appendChild(link);
            // link.click();
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
      console.log("params export tournee", data);
    },

    download(data) {
      api.downloadBonEnlevement(data).then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "text/plain" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", data.filename);
        document.body.appendChild(link);
        link.click();
      });
    }
  },

  created() {
    this.displayClients();
    console.log("planning", this.planning);
    console.log('client: ', this.planning.clients)
  },
};
</script>

<style scoped>
.btn_valider {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.accueil div[data-v-11963bce] {
  background-color: #fff;
}

.logo_bailleur {
  width: 25px;
}

.abs {
  position: absolute;
  right: 0;
  top: 0;
}

.rel {
  position: relative;
  height: 30px;
  background-color: black;
  color: #fff;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 14px;
}

.card-title:v-deep {
  margin-left: 170px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.detail-btn {
  text-transform: lowercase;
  text-decoration: underline;
}

.line_colcard {
  width: 90%;
  background-color: #333;
  padding-top: 1px;
  margin: 10px 0;
  margin-left: 20px;
}

.vignette-green {
  background-color: #93b121;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 60px;
  line-height: 80px;

  position: absolute;
  top: -20px;
  right: -20px;
  font-weight: bold;
  font-size: 20px;
}

.vignette-red {
  background-color: #d61111;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 60px;
  line-height: 80px;

  position: absolute;
  top: -20px;
  right: -20px;
  font-weight: bold;
  font-size: 20px;
}

#route
  > div
  > div
  > div
  > main
  > div
  > div.row.no-gutters
  > div:nth-child(1)
  > div
  > div
  > div
  > div:nth-child(5)
  > div.col.col-5
  > span
  > div
  > div
  > div.v-input__slot {
  font-size: 10px;
}

#snack {
  position: fixed;
  top: 0;
  right: 0;
}

::ng-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 10px;
  max-height: 10px;
}
.rose {
  color: #af2c70;
  font-size: 10px;
  text-decoration: underline;
}
::v-deep .v-input__slot {
  height: 28px !important;
  min-height: 20px !important;
}
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
  padding: 0;
}

.v-label {
  font-size: 12px;
}
.export {
  font-weight: bold;
  /* width: 40%; */
  /* text-align: center; */
}

.logo_particulier {
  /* width: 17px;
  height: 17px; */
  position: absolute;
}

.clients_height {
  height: 200px;
}

.client-list {
  background-color: #e3e3e3; 
  margin: 0 !important; 
  padding: 0 !important;
}

.client-list-item {
  background-color: #e3e3e3; 
  margin: 0 !important; 
  padding: 0 !important;
  height: 25px;
  min-height: unset !important;
}

.client-list-img {
  margin-top: 8px;
  right: 20px;
  position: unset;
}

@media screen and (max-width: 1080px) {
  .vignette-green {
    width: 40px;
    height: 40px;
    line-height: 40px;
    top: -10px;
    font-size: 14px;
  }

  .vignette-red {
    width: 40px;
    height: 40px;
    line-height: 40px;
    top: -10px;
    font-size: 14px;
  }

  .logo_particulier {
    width: 15px;
  }
}
</style>
